import React, { Fragment } from "react";
import { render } from "react-dom";

import { TextField, Checkbox, FormControl, FormControlLabel } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import Parser from "html-react-parser";

import Axios from "axios";

import {
  MuiPickersUtilsProvider, KeyboardDatePicker,
} from '@material-ui/pickers';

import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';


import Swal from 'sweetalert2';

const SwalContent = `
  <div class="swalContent">
    <div class="swalContent__txt">Grazie per averci contattato,<br />risponderemo il prima possibile.</div>
    <div class="swalContent__download">
      <a class="swalContent__downloadButton cta medium blue filled" href='/Digital Human Experience - Gruppo Digitouch.pdf' download>
        <span>Scarica il PDF</span>
      </a>
    </div>
  </div>`


const SwalSuccess = {
  title: 'Messaggio inviato',
  html: SwalContent,
  icon: 'success',
  confirmButtonText: 'Chiudi',
  onAfterClose: () => {
    location.reload()
  },
  onOpen: () => document.body.classList.add('popup-open'),
  onClose: () => document.body.classList.remove('popup-open')
}

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: [
        {
          required: true,
          type: 'TextField',
          id: 'input_1_3',
          class: 'input_1_3',
          label: 'Nome'
        },
        {
          required: true,
          type: 'TextField',
          id: 'input_1_6',
          class: 'input_1_6',
          label: 'Cognome'
        },
        {
          required: true,
          type: 'Email',
          id: 'input_2',
          class: 'input_2',
          label: 'Email'
        },
        {
          required: false,
          type: 'TextField',
          id: 'input_4',
          class: 'input_4',
          label: 'Telefono'
        },
        {
          required: false,
          type: 'TextField',
          id: 'input_3',
          class: 'input_3',
          label: 'Azienda'
        },
        {
          type: 'FreeText',
          label: 'Cliccando su “invia” confermo di aver letto l’informativa <a href="privacy" target="_blank" title="Privacy">privacy</a> ai fini della registrazione al Sito.'
        },
        {
          required: false,
          type: 'Checkbox',
          id: 'input_5',
          class: 'input_5',
          label: 'Acconsento al trattamento dei miei dati per il ricontatto da parte del Titolare per finalità di business.'
        }
      ],
      submit: {
        text: '<span>Prenota una demo <em>e scarica il PDF</em></span>'
      },
      data: {}
    }
  }



  async sendFormDataToGravity(data) {
    return new Promise(async (resolve, reject) => {
      console.log(data)
      const endpoint = `https://api.gruppodigitouch.it/wp-json/gf/v2/forms/8/submissions`;

      Axios.post(endpoint, data).then(async response => {
        if (response.status === 200) {
          resolve(true);
          console.log(response.data)

          Swal.fire(SwalSuccess)


        } else {

          console.log("FORM SUBMIT STATUS NOT 200")
          console.log(response.data)
          resolve(false)
        }
      })

    });

  }

  handleSubmit(e) {
    e.preventDefault();
    // console.log(this.state.data);
    this.sendFormDataToGravity(this.state.data)
  }
  componentDidMount() {
    //per testare l'alert
    //Swal.fire(SwalSuccess)
  }

  handleChange = (event) => {
    // console.log(event)
    //console.log(event.target.id)
    const value = event.target.value;
    let data = this.state.data;
    data[event.target.id] = value;
    this.setState({ data });
    //console.log(this.state.data)
  }

  handleCheckbox = (event, v) => {
    //console.log(event,v)
    const value = v;
    let data = this.state.data;
    data[event.target.id] = value;
    this.setState({ data });
    console.log(this.state.data)
  }

  render() {



    let { fields, action, submit } = this.state;
    //console.log(fields)
    return (
      <Fragment>
        <div id='form' className="pageForm container">

          <div className="fields">
            <form onSubmit={this.handleSubmit.bind(this)}>
              {fields.map((field, i) => (
                <div className={`field ${field.id}`} key={i}>
                  {
                    field.type == 'TextField' ?
                      <TextField
                        required={field.required}
                        id={field.id}
                        name={field.label}
                        label={field.label}
                        onChange={this.handleChange}
                      />
                      : null
                  }
                  {
                    field.type == 'Email' ?
                      <TextField
                        required={field.required}
                        type={'email'}
                        id={field.id}
                        name={field.label}
                        label={field.label}
                        onChange={this.handleChange}
                      />
                      : null
                  }
                  {
                    field.type == 'FreeText' ?
                      <div className="freeText">{Parser(field.label)}</div>
                      : null
                  }
                  {
                    field.type == 'Checkbox' ?
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            required={field.required}
                            classes={{
                              root: 'Mui-checkbox',
                              checked: 'checked',
                              disabled: 'disabled'
                            }}
                            id={`${field.id}_1`} //in gravity forms rest api version 1 a checkbox array needs to be sent as input_Number_index
                            name={`${field.id}_1`} //in gravity forms rest api version 1 a checkbox array needs to be sent as input_Number_index
                            color="primary"
                            onChange={(e) => this.handleCheckbox(e, e.target.checked)}
                          />
                        }
                        label={Parser(field.label)}
                      />
                      : null
                  }
                </div>
              ))}
              <div className={'field submit-container'}>
                <div id="FormSentinel"></div>
                <Button
                  classes={{
                    root: 'submit cta medium blue filled', // class name, e.g. `classes-nesting-root-x`
                    label: 'submit', // class name, e.g. `classes-nesting-label-x`
                  }}
                  type="submit"
                  size="large"
                  variant="outlined"
                >
                  {Parser(submit.text)}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    );
  }
}


export default Form;