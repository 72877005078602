import React, { Fragment } from "react";
import { render } from "react-dom";
import { Helmet } from "react-helmet"
import "../styles/main.scss";


import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import Hero from "../components/hero/hero";
import Highlights from "../components/highlights/highlights";
import Form from "../components/form/form";
import Features from "../components/features";
import Tools from "../components/tools";
import Intro from "../components/intro";


export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {

  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Digital Human Experience: un nuovo modo per convertire.</title>
          <meta name="description" content="Personalizzazione, integrazione, conversione: la piattaforma proprietaria Digital Human Experience migliora le relazioni tra brand e consumatori. Per eventi B2B e B2C ancora più efficaci."></meta>
          <meta property="og:title" content=" Digital Human Experience: un nuovo modo per convertire."></meta>
          <meta property="og:description" content="Personalizzazione, integrazione, conversione: la piattaforma proprietaria Digital Human Experience migliora le relazioni tra brand e consumatori. Per eventi B2B e B2C ancora più efficaci."></meta>
          <meta property="og:url" content="dhx.gruppodigitouch.it" />
          <meta property="og:image" content="/og.jpg" />
        </Helmet>
        <Header />
        <Hero />
        <Intro />
        <Features />
        <Tools />
        <Highlights />
        <Form />
        <Footer />
      </Fragment>
    );
  }
}


