import React, { Fragment } from "react";



class Video extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    componentDidMount() {

        let vidContainer = document.getElementById('pageHero');
        let play = document.getElementsByClassName('hero__play');
        let vidClip = document.getElementsByClassName('hero__video');
        let closeVideo = document.getElementsByClassName('hero__video--close');

        play[0].addEventListener("click", function (e) {
            var target = e.target;
            console.log('play', target);
            console.log('vidContainer', vidContainer);
            vidContainer.classList.add('playing');
            vidClip[0].play();
        });

        closeVideo[0].addEventListener("click", function (e) {
            var target = e.target;
            vidContainer.classList.remove('playing');
            vidClip[0].pause();
        });

        let vid = document.getElementById("homeVideo");
        if (vid) {
            vid.classList.add('loaded');
            vid.play();
            //console.log('video loaded', vid)
            setTimeout(() => vid.play(), 1)
        }

    }

    render() {
        const { src } = this.props;

        return (
            <Fragment>
                <div className="fullscreen-video-wrap">
                    <div className={'shadow'}></div>
                    <video ref={this._headerVideo} controlsList={'nodownload'} id={'homeVideo'} className={'videoInsert'} playsInline autoPlay muted loop>
                        <source src={this.props.bgVideo} type={'video/mp4'} />
                    </video>
                </div>
                <span className="hero__video--close"></span>
                <video
                    controls
                    controlsList={'nodownload'}
                    className="hero__video"
                    muted
                //poster={this.props.poster_video ? this.props.poster_video.url : null}
                >
                    <source src={src} type={'video/mp4'} />
                </video>
                {/* <div className={'video video-player'}>
                    <video controlsList={'nodownload'} className="video" muted poster={this.props.poster_video ? this.props.poster_video.url : null}>
                        <source src={video} type={'video/mp4'} />
                    </video>
                    <div className={'video-play'}>
                        <div className={'button-video play'}></div>
                    </div>
                </div> */}
            </Fragment>
        )
    }
}

export default Video;

