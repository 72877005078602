/**
 * Tools
 * A component should render a little piece of UI.
 * It should not call any api, but should hold UI logic.
 * @class Tools
 * @param {object} props - Props
 */

import React from "react";
import Parser from "html-react-parser";


export default class Tools extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
		};
	}

	componentDidMount() {
	}

	render() {


		return (
			<div className={"tools"}>
				<div className="tools__title">
					<h2>
						Perché DHX
				 </h2>
					<small>Alcuni strumenti della piattaforma</small>
				</div>
				{
					tools.sections.map((section, i) => (
						<div className="tools__section" key={i}>
							<div className="tools__sectionTitle">
								<strong>
									{section.label}
								</strong>
							</div>
							<div className="tools__list">
								{
									section.tools.map((tool, i) => (
										<div className="tools__tool">
											<div className="title">
												{tool.title}
											</div>
											<div className="abstract">
												{Parser(tool.abstract)}
											</div>

											<input type="checkbox" />
											<span></span>
											<div className="full">
												<div className="inner">
													{Parser(tool.full)}
												</div>
											</div>
										</div>
									))
								}
							</div>
						</div>
					))
				}
			</div>
		);
	}

}

const tools = {
	sections: [
		{
			label: 'Business to Consumer',
			tools: [
				{
					title: 'Consulente digitale',
					abstract: 'Un esperto reale collegato da remoto, mostra il prodotto/servizio da una postazione con un <b>Virtual Background</b>',
					full: "Un esperto reale, collegato da remoto, coinvolgerà i consumatori da una postazione personalizzata grazie ad un <b>Virtual BackGround</b> sul quale potremo caricare tutti i contenuti statici o video che desideriamo mostrare. Il consulente ingaggerà i consumatori mostrando il prodotto/servizio, rispondendo alle domande e suggerendo le soluzioni più opportune in base alle esigenze."
				},
				{
					title: 'Pop-up interattivi',
					abstract: 'Nei momenti chiave, mostra agli utenti dei mossaggi pop-up di specifiche, materiale informativo, link all\'<b>e-commerce</b>',
					full: "Durante le dirette con i consulenti, si possono far comparire dei messaggi pop-up attivati direttamente in momenti strategici, che se cliccati dai consumatori permetteranno di accedere a sezioni specifiche del sito, scaricare materiale informativo o concludere un acquisto su E-commerce. Inoltre potrebbero essere presenti infografiche o messaggi di comunicazione, che aiutino i consumatori nel percorso di apprendimento durante demo o tutorial."
				},
			]

		},
		{
			label: 'Business to Business',
			tools: [
				{
					title: 'Formazione remota',
					abstract: 'Un esperto di formazione coinvolge i promotori, da una postazione con <b>Virtual Background</b>, mostrando infografiche, pop-up, video di formazione e aggiornamento',
					full: "Un esperto di formazione, collegato da remoto, coinvolgerà i promotori da una postazione personalizzata grazie ad un Virtual BackGround sul quale potremo caricare tutti i contenuti statici o video che desideriamo mostrare. Il digital coach ingaggerà i promotori attraverso gli strumenti della piattaforma, mostrando infografiche, pop-up e video, utili alla loro formazione e/o al loro aggiornamento."
				},
				{
					title: 'Evento aziendale',
					abstract: 'Organizza gli eventi da remoto, con una <b>regia dedicata</b>',
					full: "Grazie alla versatilità della piattaforma, è possibile perfino organizzare degli Eventi Aziendali in remoto, collegando numerosi utenti alla medesima experience. Una regia dedicata all’evento permetterà di seguire in maniera dinamica l'evento e permetterà ai relatori di partecipare anche da luoghi diversi contemporaneamente e in più si avrà la possibilità di personalizzare l’experience e i contenuti in modo veloce e coinvolgente."
				},
			]
		}

	],
}