import React from "react";
import Video from "../video/video.js";
function scrollToForm() {
  const scrollBtn = document.getElementsByClassName('scrollBtn');
  const form = document.getElementById('highlightsScrollListener');

  for (var i = 0; i < scrollBtn.length; i++) {
    //console.log(scrollBtn.item(i))
    scrollBtn.item(i).addEventListener("click", function (e) {
      form.scrollIntoView({ behavior: "smooth" });
    });
  }


}
function stickCta() {
  //const headerEl = document.querySelector('.header')
  const sentinel = document.getElementById('scrollBtnSentinel')
  const formSentinel = document.getElementById('FormSentinel')
  //console.log(sentinel)
  const handler = (entries) => {
    
    if (!entries[0].isIntersecting) {
      document.body.classList.add('stickCtaEnabled')
      //console.log('enabled')
    } else {
      //console.log('disabled')
      document.body.classList.remove('stickCtaEnabled')
    }
  }
  
  const handlerReverse = (entries) => {
    
    if ((window.scrollY) >= 160) {
      if (!entries[0].isIntersecting) {
        //console.log('form hidden')
        document.body.classList.add('stickCtaEnabled')
      } else {
        //console.log('form visible')
        document.body.classList.remove('stickCtaEnabled')
        //console.log('disabled')
      }
    }
  }
  // create the observer
  const observer = new window.IntersectionObserver(handler)
  const formObserver = new window.IntersectionObserver(handlerReverse)
  // give the observer some dom nodes to keep an eye on
  observer.observe(sentinel)
  formObserver.observe(formSentinel)
}
class Hero extends React.Component {
  componentDidMount() {
    scrollToForm();
    stickCta();
  }
  render() {
    return (
      <>
        <section id="pageHero" className="hero">
          <div className="hero__content">
            <h1>Digital Human Experience, un nuovo modo per convertire.</h1>
            <p>La piattaforma proprietaria del Gruppo DigiTouch permette di stabilire una nuova forma di relazione fra brand e cliente, usando la tecnologia per trasformare la customer experience e continuare a convertire.</p>
            <span className="hero__play"></span>
          </div>
          <Video src={'./video/dhx.mp4'} bgVideo={'./video/dhx-10.mp4'} />
          <div id='scrollBtn' className="scrollBtn cta medium white">
            <div id="scrollBtnSentinel"></div>
            <span>Prenota una demo <em>e scarica il PDF</em></span>
          </div>
        </section>
        <div id='scrollBtnSticky'>
          <div className="container">
            <div className="scrollBtn cta medium white">
              <span>Prenota una demo <em>e scarica il PDF</em></span>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Hero;
