import React, { Fragment } from "react";
import { render } from "react-dom";

class Header extends React.Component {
  componentDidMount() {

  }
  render() {
    return (
      <section id="highlights" className="highlights container">
        <div id="highlightsScrollListener"></div>
        <div className="highlights__content">
          <h2 className="highlights__title">Prenota una demo</h2>
          <p className="highlights__text">È uno strumento innovativo e prezioso per il tuo business perché offre la possibilità di sviluppare eventi e attività retail in una dimensione del tutto nuova, sfruttando una piattaforma tecnologica dalle molteplici possibilità di personalizzazione per stabilire, mantenere e approfondire le relazioni con i consumatori e clienti. </p>
          <p className="highlights__text"><strong>Porta il tuo business a un altro livello.</strong></p>
        </div>
      </section>
    );
  }
}
export default Header;