/**
 * Features
 * A component should render a little piece of UI.
 * It should not call any api, but should hold UI logic.
 * @class Features
 * @param {object} props - Props
 */

import React from "react";
import VisibilitySensor from "react-visibility-sensor";

class Features extends React.Component {
	onEnter(e) {
		//console.log(e.event.target.childNodes[1])
		//console.log('enter')
	}
	onLeave(e) {
		//console.log(e.event.target.childNodes[1])
		//console.log('leave')
	}
	render() {
		return (
			<div className={"features"}>

				<VisibilitySensor partialVisibility offset={{ top: 200, bottom: 200 }} >

					{({ isVisible }) =>

						<div className={`feature ${isVisible ? 'isVisible' : ''}`}>
							<div className="col feature__txt">

								<h2 className="feature__title">
									Gli eventi <b>digitali:</b><br />la nuova frontiera della comunicazione.
									{/* <small>(o sovraccarico cognitivo)</small> */}
								</h2>
								<p>
									La nostra missione è unire la ricchezza dei contenuti online con l’esperienza offline in una nuova forma di interazione tangibile, <em>one to one</em> oppure <em>one to many</em>, che permette di continuare a creare valore e a comunicare vicinanza e calore. Oggi più che mai non è importante esserci, ma <em>fare la differenza.</em>
									{/* Ci sono più modi per attirare l’attenzione sul proprio contenuto e imporsi sul rumore, e gli <b>eventi digitali</b> sono uno di questi: */}
									<em>

									</em>
								</p>
							</div>
							<div
								className="col feature__img"
								style={{
									backgroundImage: "url(./features/infobesity.jpg)",
									backgroundPosition: 'center',
									backgroundRepeat: 'no-repeat'
								}}
							>

							</div>
						</div>

					}
				</VisibilitySensor>

				<VisibilitySensor partialVisibility offset={{ top: 200, bottom: 200 }} >

					{({ isVisible }) =>

						<div className={`feature ${isVisible ? 'isVisible' : ''}`}>
							<div className="col feature__txt">

								<h2 className="feature__title">
									I <b>vantaggi</b> in tre parole.
									{/* <small>(o sovraccarico cognitivo)</small> */}
								</h2>
								<p>
									<ol>
										<li>
											<b>Organizzazione:</b> ridurre al minimo gli sforzi di logistica, di allestimento, di catering e di ospitalità.
										</li>
										<li>
											<b>Budget:</b> risparmiare in termini di costi per finanziare l’evento.
										</li>
										<li>
											<b>Pubblico:</b> superare le distanze e i limiti di capienza.
										</li>
									</ol>
								</p>
							</div>
							<div
								className="col feature__img"
								style={{
									backgroundImage: "url(./features/eventi-digitali.jpg)",
									backgroundPosition: 'center',
									backgroundRepeat: 'no-repeat'
								}}
							>
							</div>
						</div>

					}
				</VisibilitySensor>



			</div>
		);
	}

}
export default Features;
