/**
 * Intro
 * A component should render a little piece of UI.
 * It should not call any api, but should hold UI logic.
 * @class Intro
 * @param {object} props - Props
 */

import React from "react";

export default class Intro extends React.Component {

	render() {
		return (
			<section className={"intro"}>
				<div className={`introItem`}>
					<div className="col introItem__txt">
						<h2 className="introItem__title">
							La piattaforma tecnologica per i tuoi <b>eventi digitali</b>
						</h2>
						<p>
							Sviluppa eventi e attività retail in una dimensione del tutto nuova grazie a una <b>piattaforma</b> dalle molteplici possibilità di personalizzazione, per mantenere e approfondire la relazione con i consumatori.
						</p>
					</div>
					<div
						className="col introItem__img"
						style={{
							backgroundImage: "url(./intro/dhx-platform.jpg)",
							backgroundPosition: 'center',
							backgroundRepeat: 'no-repeat'
						}}
					>
					</div>
				</div>
			</section>
		);
	}

}
